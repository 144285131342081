if (typeof NodeList !== "undefined" && NodeList.prototype && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

//menu button interaction
const menuControl = document.querySelectorAll( '.js--menu-control' );
const navigation = document.querySelector( '.js--site-navigation' );
menuControl.forEach(function(menuButton) {
	menuButton.addEventListener( 'click', function() {
		menuButton.classList.toggle('open');
		navigation.classList.toggle('open');
	});
});

//open din info
const dinInfoButton = document.querySelectorAll( '.js--open-din-info' );
const dinInfoModal = document.querySelector( '.js--modal-1' );
const wrongDinModal = document.querySelector( '.js--modal-2' );
const modalCloseButton = document.querySelectorAll( '.js--close-modal' );

dinInfoButton.forEach(function(dinInfoBtn) {
	dinInfoBtn.addEventListener( 'click', function() {
		dinInfoModal.classList.add('visible');
		document.body.classList.add('modal-open');
	});
});

//close all gate modals
modalCloseButton.forEach(function(modalCloseBtn) {
	modalCloseBtn.addEventListener( 'click', function(e) {
		e.target.closest('.js--modal').classList.remove('visible');
		document.body.classList.remove('modal-open');
	});
});

//din gate
const dinGateEl = document.getElementById("din-gate");
const mainEl = document.getElementById("main");

function showGate(showError) {
    if(showError) {
        wrongDinModal.classList.add('visible');
        document.body.classList.add('modal-open');
    }

    mainEl.style.opacity = 1;
    document.body.classList.add("din-bg");
    localStorage.removeItem("din");
    sessionStorage.removeItem("din");
}

// Checks if an html file exists at the specified route with the DIN as a suffix
// If it does, we load that content and inject it into #main
// else we display an error and ensure that the DIN gate form is visible
function loadDinContent(din) {
    din = din.replace(/\D/g, '');

    if (din.length === 0) return showGate(true);

    fetch(`./${din}/${document.body.getAttribute("data-slug")}.html`).then(function(response) {
        if (!response.ok) return showGate(true);

        response.text().then(function(html) {
            mainEl.innerHTML = html;
            document.body.classList.remove("din-bg");
            document.body.classList.add("post-din");
            mainEl.style.opacity = 1;
            localStorage.setItem("din", JSON.stringify({din, din, t: Date.now()}));
            sessionStorage.setItem("din", din);
        }, function(e) {
            console.error(e);
        });

    }, function(e) {
        console.error(e);
    });
}

// Form event listener
dinGateEl.addEventListener("submit", function(e) {
    e.preventDefault();
    loadDinContent(dinGateEl.din.value);
});

// If a DIN exists in local storage within valid ttl, or in session storage, try fetching content, else show the DIN gate form
window.getDin.then(function(dinObj) {
    var ttl = 10800000; // 3 hours

    if (dinObj && dinObj.t) {
        if ((dinObj.t + ttl) >= Date.now()) return loadDinContent(dinObj.din);
        var din = sessionStorage.getItem("din");
        if (din) return loadDinContent(din);
    }
    
    showGate();
}, function(e) {
    console.error(e);
    showGate();
});

